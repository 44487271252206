export async function verifyRollNo(formData) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(
      process.env.REACT_APP_API + `/v1/api/verifyRollNo`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        //   Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formData),
      },
    )
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }


  export async function getCutOffDetails(name) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/getAll/categoryCutOffDetail?counsellingName=${name}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${token}`,
      },
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }



  export async function sendOtp(formData) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/sendOTP`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }

  export async function verifyOtp(formData) {
    const token = localStorage.getItem('token')
    const rawResponse = await fetch(process.env.REACT_APP_API + `/v1/api/verify/otp`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        // Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    })
    const content = await rawResponse.json()
    if (rawResponse.status === 200) {
      return { body: content.body, success: true, message: '' }
    } else {
      return { body: [], success: false, message: content.message }
    }
  }


